// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:less
@import "../../bower_components/bootstrap/less/bootstrap.less";
// endbower

@import "common/_variables";
@import "common/_global";
@import "components/_buttons";
@import "components/_comments";
@import "components/_forms";
@import "components/_grid";
@import "components/_wp-classes";
@import "layouts/_header";
@import "layouts/_sidebar";
@import "layouts/_footer";
@import "layouts/_pages";
@import "layouts/_posts";


@font-path:        "/wp-content/themes/blank-sage/dist/fonts/";
@img-path:        "/wp-content/themes/blank-sage/dist/img/";




@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-thin-italic-webfont.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-light-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-light-italic-webfont.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-book-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-book-italic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-regular-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-semibold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-semibold-italic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-bold-italic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-xbold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-xbold-italic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-heavy-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-heavy-italic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Glober';
  src: url('@{font-path}glober-black-italic-webfont.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Fiesole';
  src: url('@{font-path}Fiesole_Text-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fiesole';
  src: url('@{font-path}Fiesole_Text_Italic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Fiesole';
  src: url('@{font-path}Fiesole_Text_Bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fiesole';
  src: url('@{font-path}Fiesole_Text_Bold_Italic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

/* ==========================================================================
   Base
   ========================================================================== */
html, body {
    max-width: 100%;
    overflow-x: hidden;
		min-height: 100%;
}


body { 
	color:#666;
	background:black;
}
body.mce-content-body {
	background:white;
	color:#333;
}

#wrapper {
background:#fff;
overflow:visible;
min-height: 100%;
}

.wrap.container {
	margin:40px 0px 100px;
}

.home .wrap.container {
	margin-top:0px;
}
.home .blocks {
	margin-top:40px;
}
.home .block-content>a {
	position:absolute;
	display:inline-block;
	width:100%;
	height:100%;
}
.home .block-content p>a {
background:#eee;
}
.home .block-content p>a:hover {
background:#ddd;
}

.home .block-content>a:hover,
.home .block-content>a:focus {
background:none;
}
.container {
margin-right: 0px;
margin-left: 0px;
padding-left: 0px;
padding-right: 0px;
float:right;
}

.col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
padding-left: 0px;
padding-right: 0px;
}

p {
	font-family:'Fiesole';
}

.thin {
	font-weight:200;
	font-family: @font-family-sans-serif;
}
a {
	color:#666;
}
a:hover {
	text-decoration:none;
}
main a {
	color:#333;
	background:#eee;
	
}
main a:hover {
	text-decoration:none;
	color:#333;
	background:#DDD;
}
.sidebar {
float: left;
width: 66.66666667%
}

/* ==========================================================================
   Header
   ========================================================================== */

.banner {
	background:#fff;
	height:190px;
	margin:0px;
 }
 
 .navbar-default {
border-color: #4d4d4d;
}
.across .navbar-default {
border-color: #fff;
}
#menu-primary-navigation {
	margin-right:40px;
}
 .banner .container {
 	margin-top:100px;
 }
.navbar-brand {
	font-size:38px;
	font-weight:300;
	padding: 15px 0px 0px;
}
.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
margin-left: 0px;
}
.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-brand {
	color:#000;
}
.navbar-nav {
	float:right;
}
.navbar-nav>li>a {
	padding-bottom:0px;
	padding-top:25px;
	font-weight:500;
	font-size:16px;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
	color: #555;
	background-color: transparent;
}
header.navbar {
}
#triangle {
        display: block;
        position: absolute;
        left:45%;
        bottom:-34px;
        width:12px;
        height:13px;
        overflow:hidden;
}
#triangle svg {
position:absolute;
top:0px;
left:0px;
	
}
header .container {
	padding:0px 15px 0px 0px;
}

.featuredImg img {
	width:100%;
	height:auto;	
}
#projectFeaturedImg {
	position:relative;
	overflow:auto;
}
.featuredImg {
	overflow:hidden;
}
#projectFeaturedImg .container {
	position:relative;
}
#projectTitle {
	position:absolute;
	right:0px;
	bottom:0px;
	background:#000;
}
.across #projectTitle {
	width: 100% * (5/6);
}
.across .overflow #projectTitle {
	width: 100% * (4/6);
}

.featured_img.side #projectTitle {
	position:absolute;
	margin-top:0px;
	width: 100% * (2/6);	
}
.side .side_float_right {
	float:right;
}
.side.featured_img .col-md-8.side_float_right {
	float:left;
}
.side #projectTitle {
	position:relative;
	overflow:visible;
	margin-top:20vh;
	padding:1px 0px;
}
.across .overflow #projectTitle {
	position:relative;
	margin-top:-60px;
	
}
.overflow #projectTitle .entry-title {
	
}
.overflow {
height:100%;
width:200vw;
margin-left:-100vw;
margin-bottom:40px;
}
.overflow>div {
margin-left:100vw;
}
.side #projectTitle{
	
}


.single-project .entry-title {
margin: 20px 0px 30px 20px;
}
.overflow .entry-title {
margin: 20px 0px 30px 0px;
padding: 25px 0px 0px 0px;
}


/* ==========================================================================
   Content
   ========================================================================== */

.content { }

.custom_content .overflow {
	width:auto;
	margin-left:-600px;
	padding: 40px 55px 40px 600px;
	height:auto;
	
}

.cream {
	background:#f9f1e8;
}

.custom_content .overflow img {
	width: 100%;
	height:auto;
}

.custom_content .overflow>div {
margin-left:0px;
}


/* ==========================================================================
   Masonry
   ========================================================================== */

.block { color:#fff; overflow:hidden; 
   position: relative;
    float: left;
}
.block-content {  
	position:absolute;
	width:100%;
	height:100%;
	padding: .5em 1em;
	left:0;
}

.home .block {
	background-position: center;
}
.home .block-content {
	padding:0;
}
.home .block-content .block-text {
	padding: 1em 1em .5em;
}
.block-content div.v_middle {  
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
}
.block-content div.v_top {  
  position: absolute;
	top:0;
}
.block-content div.v_bottom {  
  position: absolute;
	bottom:0;
}
.related_projects .block-content,
body.work .block-content,
body.single-project .block-content {  
	padding: 0em;

}
body.blog .block-content {  
	background: #fff;
	color:#666;
	padding: 40px 40px 0px 0px;
}
body.blog .block-content .block-text {  
}
body.blog .stand_image .block-content .block-text {  

}
body.blog .block-content img {  
	
}
body.blog .block-text {  
	
}
body.blog div.blocks.shuffle {
	overflow:auto;
}

body.blog div.block-content {
	position:relative;
	overflow:auto;
}
body.blog article.block {
	overflow: visible;
}


body.blog .block-content:hover {  

}
body.blog article.format-quote .block-content p {
	display:inline-block;
	font-size:28px;
	padding-left:60px;
	margin-top: -60px;
}
body.blog article.format-quote .block-content {
	margin-top: 30px;
	overflow:visible;
}

body.blog .page-header {
	margin-bottom:0px;
}
article.format-image img {
	width:100%;
}
article.format-image .block-content {
}
article.format-quote .block-content {
  quotes: "\201C""\201D""\2018""\2019";
}
article.format-quote .block-content:before {
  color: #000;
  content: open-quote;
  font-size: 7em;
  line-height: 1px;
  margin-right: 10px;
  vertical-align: -45px;
	font-family:'Fiesole';
}


article.format-image .modal {
	z-index:1050;
	
}
article.format-image .modal-dialog {
	width:90%;
	}
	
	.modal-content {
		border-radius:0px;
	}
body.blog .block-content h1 {  
	font-weight:100;

}
body.blog .block-content h2 a {  
	line-height:1.4;
	font-size:30px;
	background:#eee;
	color:#000;
}
.block.post_format-post-format-image img {
	padding:5px;
	background:white;
	max-width:99%;
	margin:-10px 0px 12px 1%;
	box-shadow: 0px 0px 4px #ccc;
}
.post_format-post-format-image img {

}
.post_format-post-format-image a {
	background:none;

}
.post_format-post-format-image a:hover {
	background:none;
}
.post_format-post-format-image a:focus {
	outline:0px;
}
.content_thumbnail {
	float:left;
	margin:0px 10px 10px 0px;
}
.thumbnail, .wp-caption {
border-radius:0px;
}

body.blog .block-content p {  
	font-size:20px;
	line-height:1.2;
}
.block-content div.images {  
  position: relative;
	width:100%;
  top: 0%;
  transform: none;
	height:100%;
}
.block-content div.image {  
	width:100%;
	height:100%;
}
.block-content div.work_title {  
  position: absolute;
	width:100%;
  bottom: 0%;
  padding:.5em;
	   transition: opacity .25s ease-in-out;
}
.block-content:hover div.work_title {  
	opacity:1;
}
div.work_title p {  
	font-family:"glober";
	font-size:16px;
	
}
.block-content div.work_title a {  
  color:#fff;
}
.block-content div.work_title a h5 {  
  margin-bottom:8px;
  font-size:22px;
}
.block-content div.work_title a p {  
  margin-bottom:0px;
}

.block-content div.image img {  
	width: 100% !important;
	height:100% !important;
}
body.work .carousel,
body.work .carousel-inner>.item>img {
	width: 100% !important;
	height:100% !important;
}




.block-content div h1,
.block-content div h2,
.block-content div h3,
.block-content div h4,
.block-content div h5 {  
	margin-top:0px;
}

.block img {
	max-width:100%;
	height:auto;
}

.animate_yes .block-content.clear {
	background-color:rgba(0,0,0,0) !important;
}
.animate_yes .block-content div.clear {
	opacity: 0;
}
.animate_yes .block-content.clear.hover {
	background-color:none;
}
.animate_yes .block-content.hover div.clear {
	opacity: 1;
}
.animate_yes .block-content > div {
  -webkit-transition: opacity 2s; /* For Safari 3.1 to 6.0 */
    transition: opacity 2s;  
}
.animate_yes .block-content {
  -webkit-transition: background-color 2s; /* For Safari 3.1 to 6.0 */
    transition: background-color 2s;  
}

#work_type {
	list-style:none;
	padding-left:0px;
	font-size:22px;
}
#work_type li {
	display:inline-block;
}

#allProjects {
	color:red;
}
#allProjects.on {
	pointer-events: none;
}
#work_type li a {
  border:1px #666 solid;
  color:#666;
  padding: 2px 6px;
  margin-right:4px;
  background:#fff;
  font-size:75%;
}
#work_type li a:hover {
	background:#DDD;
}
#work_type li a.on {
  background:black;
  border-color:black;
  color:#fff;
}

#blockSize {

position: absolute;
  opacity: 0;
  visibility: hidden;
}


/* ==========================================================================
   Primary Content
   ========================================================================== */

.main { }

/* Gallery Shortcode */
.gallery-row { padding: 15px 0; }



/* ==========================================================================
   Sidebar
   ========================================================================== */

.sidebar { }



/* ==========================================================================
   Posts
   ========================================================================== */

.hentry header { }
.hentry time { }
.hentry .byline { }
.hentry .entry-content { }
.hentry footer { }


.prev-next-posts {
	text-align:center;
	font-size:30px;
	font-weight:200;
	color:#000;
	margin-top:40px;
}
.prev-next-posts div {
	display:inline-block;
	margin:0px 10px;
}
.prev-next-posts a {
	padding:0px 5px;
}

/* ==========================================================================
   Footer
   ========================================================================== */

.content-info { 
	clear:both;
	padding:30px 0px;
	overflow:auto;
	color:#fff;
	width:100vw;
	background:#000;
}
.content-info .row { 
	margin-right:0px;
}
.content-info a { 
	color:#fff;
}
.content-info { 
}

/* ==========================================================================
   Type
   ========================================================================== */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	line-height:1.33;
}
.single-project .entry-title {
color:#fff;
}

.page-header {
	margin-top:0px;
	border-bottom:0px;
	margin-bottom:0px;
}
.blog .page-header {
	margin-bottom:20px;
}

.page-header h1 {
	font-size:24px;
	font-weight:normal;
	margin-top:0px;
}
.work {

}
.work .row .col-sm-11 > h3:first-child {
	margin-top:10px;
}

aside.sidebar {

}
aside.sidebar ul {
	margin:0px;
	padding:0px;
	list-style:none;
}
aside.sidebar section {
	margin-right:20px;
}

h1, h2, h3, h4, h5 {
	color:black;
}

.work_title h5 {
	color:#fff;
}

h2 {
	font-weight:200;
	font-size:36px;
}
h3,
.gsection h2 {
	font-weight:400;
	font-size:26px;
}
h4 {
	font-weight:400;
	font-size:22px;
}
h5 {
	font-weight:600;
	font-size:17px;
}
h6 {
	font-weight:600;
	font-size:14px;
}

.single .entry-content img {
	max-width:100%;
}
.btn {
	margin:15px 0px;
}
/* ==========================================================================
   WordPress Generated Classes
   See: http://codex.wordpress.org/CSS#WordPress_Generated_Classes
   ========================================================================== */

.aligncenter { display: block; margin: 0 auto; }
.alignleft { float: left; }
.alignright { float: right; }
figure.alignnone { margin-left: 0; margin-right: 0; }

.search-submit {
	margin-top:0px;
}


/* ==========================================================================
   Media Queries
   ========================================================================== */
   
   .search article {
   	overflow:auto;
   	margin-bottom: 15px;
   }
   .search article .image.item {
   	width: 50%;
   	max-width: 200px;
   	float:left;
   	margin: 0px 15px 15px 0px;
   }
   .search article .image.item img {
   	max-width: 100%;
   }



#wrapper {
    animation: fadein .6s;
    -moz-animation: fadein .6s; /* Firefox */
    -webkit-animation: fadein .6s; /* Safari and Chrome */
    -o-animation: fadein .6s; /* Opera */
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

/* ================================================================================================================================================
   Media Queries
   ========================================================================== */

@media (max-width: @screen-sm) { 
.btn {
	white-space:normal;
}

	#wrapper {
	width:100%;
	padding:0 10px;
	}
	
	
.content-info { 
	margin:0 -10px;
}
.content-info .container { 
	margin:0 10px;
	float:left;
}
header .container {
	float:left;
	width:100%;
}
.container>.navbar-header {
margin:0px;
}
.navbar-toggle {
	float:right;
}
.wrap.container {
	width:100%;
}
	.width_6, .width_5, .width_4, .width_3, .width_2, .width_1 {
		width: 100%;
	}
	.height_6 {
		padding-bottom: 100%;
	}
	.height_5 {
		padding-bottom: percentage( 1/6 * 5 );
	}
	.height_4 {
		padding-bottom: percentage( 1/6 * 4 );
	}
	.height_3 {
		padding-bottom: percentage( 1/6 * 3 );
	}
	.height_2 {
		padding-bottom: percentage( 1/6 * 2 );
	}
	.height_1 {
		padding-bottom: percentage( 1/6 * 1 );
	}

body.work .block.width_2, 
body.work #blockSize {
		width: percentage( 1/6 * 3 );
	}
body.work .block.height_2, 
body.work #blockSize {
		padding-bottom: percentage( 1/6 * 3 );
	}

.related_projects .block-content div.image img {
	width:auto !important;	
}

#project_rows .row>div {
	margin:15px 0px;
}
}

@media (min-width: @screen-sm) { 

	#wrapper {
	min-width:90%;
	max-width:800px;
	}


	.width_6 {
		width: 100%;
	}
	.width_5 {
		width: percentage( 1/6 * 5 );
	}
	.width_4 {
		width: percentage( 1/6 * 4 );
	}
	.width_3 {
		width: percentage( 1/6 * 3 );
	}
	.width_2 {
		width: percentage( 1/6 * 2 );
	}
	.width_1 {
		width: percentage( 1/6 * 1 );
	}

	.height_6 {
		padding-bottom: 100%;
	}
	.height_5 {
		padding-bottom: percentage( 1/6 * 5 );
	}
	.height_4 {
		padding-bottom: percentage( 1/6 * 4 );
	}
	.height_3 {
		padding-bottom: percentage( 1/6 * 3 );
	}
	.height_2 {
		padding-bottom: percentage( 1/6 * 2 );
	}
	.height_1 {
		padding-bottom: percentage( 1/6 * 1 );
	}

	
	

}
@media (min-width: @screen-md) { 


	#wrapper {
	min-width:90%;
	max-width:1000px;
	}
}


@media (min-width: @screen-lg) {
	#wrapper {
	max-width:1270px;
	}
}

@media (max-width: @screen-lg) { 
	
	.home .block-content h1 {
	font-size: 44px;
}

}
@media (max-width: @screen-sm-max) { 

	
#services_tabs {
    padding-top: 30px !important;
    
    }
#services_tabs li {
    padding-right: 15px !important;
    
    }


	.home .block-content h1 {
	font-size: 36px;
}
#projectFeaturedImg {
    position: static;
    overflow: auto;
}

#projectFeaturedImg .container {
	clear:both;
	position:static;
}
#projectTitle {
		padding:10px 0px 5px;
    position: static;
    width:100% !important;
    }
    .across .overflow #projectTitle {
    	margin-top:-40px;
    	
    }
    .overflow .entry-title {
    	padding-top:0px;
    	margin-left:0px
    	
    }
    
.side #projectTitle { 
	position:relative;
	width:100vw !important;
	margin: 0px 0px 0px -100vw;
}
.side #projectTitle .entry-title { 
    position: relative;
    left: 100vw;
    width:750px;
    margin: 10px 0px 10px -750px;
}
.side.featured_img #projectTitle { 
	position:relative;
	 margin: 0px;
	width:100% !important;
	 
}
.side.featured_img #projectTitle .entry-title {
    position: relative;
    left: 0;
    margin: 10px 0 10px 0px;
    padding-left:15px;
}

.side.featured_img .row {
    margin-right: 0px;
}

#projectTitle .entry-title {
    margin-top: 0px;
    margin-bottom: 5px;
}
#project_rows .row {
   display: block !important;

}
#project_rows .row>div {
   padding: 0px 15px 0px 0px;
}

}
@media (min-width: @screen-xs) and (max-width: @screen-md) { 
	
	.work_title p {
		display:none; 
	}
	.block-content div.work_title a h5 {
    font-size: 20px;
	}
	
}

@media (max-width: @screen-sm-max) { 
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding:0px 10px;


}
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { 
	.row {
		margin-right:0px;
	}
.side_title {
		margin-right:-25px;
		height:98px;
		width:auto;

}
.side .side_float_right {
	clear:both;
}



.main {
	padding: 0px 10px;
}	
.row {
	
	margin: 0px -10px;

}
}
@media (max-width: @screen-sm) { 

body.blog article.format-quote .block-content {
	padding-top:30px;
	margin-top: 0px;
}
body.blog article.format-quote .block-content p {
	margin-top: 0px;
	padding-left:0px
}
article.format-quote .block-content:before {
	display:none;
}
.page-header {
    margin-bottom: 0px;
    padding-bottom: 0px;
    
    }
div[class^="col-md-"] {
	width:100%;
}
#project_rows .row {
   display: block !important;

}
#project_rows .row>div {
   padding: 0px 0px;
}

#projectFeaturedImg {
    margin: 0 -10px;
    float:right;
    width:100vw;
}
#projectFeaturedImg .container {
	width:100%;
}
#projectFeaturedImg .row {
    margin: 0px;
    
}
#projectTitle .entry-title {
	margin-left: 10px;
}

.side #projectTitle { 
	position:static;
	width:100% !important;
	margin:0px;
	padding: 1px 0px 1px 0px;

}
.side_title	{
	padding:0;
	position:relative;
	left:0px;
	width: 100vw !important;
}
.side #projectTitle .entry-title { 

    position: static;
    margin: 10px 0px 10px 0px;
    padding-left:10px;
	width:auto;
}

.side.featured_img #projectTitle .entry-title {
	width:auto;
}

.full_width {
	margin-right:-10px;
	padding-right:10px;
	
}
.post_format-post-format-image img {
    padding: 3px;
		margin: 10px 0px;
}

body.blog .post_format-post-format-image .block-content {
	padding:15px 6px;
	margin: 0px -6px;
}
body.blog .post_format-post-format-image {

}
body.blog .block-content {
    padding: 0px;
}

h1, .h1 {
    font-size: 44px;
    font-weight:200;
}


.navbar-nav {
	float:left;
	clear:left;
	margin:0px 0px 0px -10px;
}
#triangle {

    bottom: -24px;
    }
.banner .container {
    margin-top: 60px;
}
	
	.home .block-content h1 {
	font-size: 30px;
}

	.height_6, .height_5, .height_4 {
		min-height: 380px;
	}
	.height_3, .height_2, .height_1 {
		min-height: 190px;
	}
	.height_6, .height_5, .height_4, .height_3, .height_2, .height_1 {
		height:auto !important;
		padding-bottom:0px;
		overflow:auto;
		position:static;
}
.related-blocks .block.height_2, 
.related-blocks #blockSize {
		padding-bottom: percentage( 1/6 * 3 );
		width:50%;
		position:relative;
	}

.home .block {
    position: static !important;
    top: auto !important;
    left: auto !important;
    visibility: visible;
    opacity: 1;
    transform: none !important;
    transition: transform 250ms ease-out, opacity 250ms ease-out;
    
    }

.home .block-content {
	position:static;
		height:auto !important;
		overflow:auto;
}
.block-content div.v_top,
.block-content div.v_middle,
.block-content div.v_bottom {
		top:0;
		position:static;
  transform: translateY(0%);
}

.work .blocks {
	margin:40px -10px 0px -10px;
}

.side.featured_img .wrap .entry-content > h3:first-child,
.across.featured_img .wrap .entry-content > h3:first-child {
	margin-top:10px;
}
}


@media (max-width: @screen-xs-min) { 

.overflow {
	margin:0px -20px 20px;
	width:100vw;
	
} 



.side.featured_img .wrap .entry-content > h3:first-child,
.across.featured_img .wrap .entry-content > h3:first-child {
	margin-top:0px;
}
.overflow>div {
	margin:0px;
} 
.related-blocks .block.height_2, 
.related-blocks #blockSize {
		padding-bottom: percentage( 1/6 * 3 );
		width:100%;
		position:relative;
	}

.navbar-nav {

	margin:0px;
	width:100%;
}

.navbar-nav .menu-item:nth-child(4) {
	clear:left;

}
#triangle {
    display: none;
}
.navbar-header {
	margin-right: -15px;

}
.nav>li {
	display: inline-block;
	float:left;
}

.nav>li>a {
padding: 5px 15px;
}
.banner {
	height: 130px;
}
.banner .container {
    margin-top: 60px;

}

.navbar-toggle {
    position: relative;
    float: right;
    margin-right: 0px;
    padding: 9px 10px;
    margin-top: 12px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0px;
    border-color: #000;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #000;
}

.navbar-default .navbar-collapse {
    border: 0px;
    border-bottom:1px solid #000;
    margin:15px -15px 0px 0px;

}

.home .blocks {
	margin:40px -10px 0px -10px;
}

.work .blocks {
	margin:40px -10px 0px -10px;
}
body.work .block.width_2, 
body.work #blockSize {
    width: 100%;
}
body.work .block.height_2 {
	min-height:150px;
}
body.work .block-content div.image img {
    width: auto !important;
    height: 100% !important;
}
.block-content div.work_title {
    position: absolute;
    width: 50%;
    top: 0;
    right: 0;
    padding: .5em;
    -webkit-transition: opacity .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
}

.content-info h3 { 
	font-size: 24px;
}



}

@media (min-width: @screen-sm) and (max-width: @screen-md) { 


}





























.gform_wrapper ul {
  padding-left: 0;
  list-style: none; }

.gform_wrapper li {
  margin-bottom: 15px; }

.gfield_radio li, .gfield_checkbox li {
display: block !important;
clear:both !important;
}
.gfield_radio input, .gfield_checkbox input {
margin-left:6px !important;
}

.gform_wrapper li {
	overflow:auto;
}
.gform_wrapper form {
  margin-bottom: 0; }

.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: #b94a48; }

.ginput_container input, .ginput_container select, .ginput_container textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  transition: none }
  
  input.gform_button_select_files {
  	width:auto;
  	display:inline-block;
  }
  .gfield_radio input,
  .gfield_checkbox input {
  	display:inline-block;
  	width:auto;
  	height:auto;
  	padding:0px;
  	margin:0px;
  	margin-right:6px;
  }
  .gfield_radio li,
  .gfield_checkbox li {
  margin-bottom:5px;
  display:inline-block;
  float:left;
  margin-right:30px;
}
  .gfield_radio label,
  .gfield_checkbox label {
  font-size:16px;
  font-weight:400;
}

.ginput_container input:-moz-placeholder, .ginput_container select:-moz-placeholder, .ginput_container textarea:-moz-placeholder {
  color: #999; }

.ginput_container input::-moz-placeholder, .ginput_container select::-moz-placeholder, .ginput_container textarea::-moz-placeholder {
  color: #999; }

.ginput_container input:-ms-input-placeholder, .ginput_container select:-ms-input-placeholder, .ginput_container textarea:-ms-input-placeholder {
  color: #999; }

.ginput_container input::-webkit-input-placeholder, .ginput_container select::-webkit-input-placeholder, .ginput_container textarea::-webkit-input-placeholder {
  color: #999; }

.ginput_container input:focus, .ginput_container select:focus, .ginput_container textarea:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6); }

.ginput_container input[disabled], .ginput_container select[disabled], .ginput_container textarea[disabled], .ginput_container input[readonly], .ginput_container select[readonly], .ginput_container textarea[readonly], fieldset[disabled] .ginput_container input, fieldset[disabled] .ginput_container select, fieldset[disabled] .ginput_container textarea {
  cursor: not-allowed;
  background-color: #eee; }

textarea.ginput_container input, textarea.ginput_container select, textarea.ginput_container textarea {
  height: auto; }

.ginput_container textarea {
  height: auto; }
.gform_fields {
	overflow:auto;
}
.gform_body {
	
	overflow:visible;
}
.gform_button,
input.gform_button_select_files {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  color: #fff;
  background-color: @brand-primary;
  border-color: @brand-primary; }

.gform_footer .gform_button {
	display:block;
	margin: 0 auto;
	font-size:28px;
	font-weight:300;
	margin-top:30px;
	padding:6px 20px;
}


.gform_button:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.gform_button:hover, .gform_button:focus {
  color: #333;
  text-decoration: none; }

.gform_button:active, .gform_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.gform_button.disabled, .gform_button[disabled], fieldset[disabled] .gform_button {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

.gform_button:hover, .gform_button:focus, .gform_button:active, .gform_button.active, .open .dropdown-toggle.gform_button {
  background-color: white;
  color: @brand-primary;
  border-color: @brand-primary; }

.gform_button:active, .gform_button.active, .open .dropdown-toggle.gform_button {
  background-image: none; }

.gform_button.disabled, .gform_button[disabled], fieldset[disabled] .gform_button, .gform_button.disabled:hover, .gform_button[disabled]:hover, fieldset[disabled] .gform_button:hover, .gform_button.disabled:focus, .gform_button[disabled]:focus, fieldset[disabled] .gform_button:focus, .gform_button.disabled:active, .gform_button[disabled]:active, fieldset[disabled] .gform_button:active, .gform_button.disabled.active, .gform_button[disabled].active, fieldset[disabled] .gform_button.active {
  background-color: white;
  color: @brand-primary;
  border-color: @brand-primary; }

.gform_wrapper .gfield_error .gfield_label {
  color: #b94a48; }

.gform_wrapper .gfield_error input, .gform_wrapper .gfield_error select, .gform_wrapper .gfield_error textarea {
  border-color: #eed3d7;
  background-color: #f2dede;
  color: #b94a48; }

.gform_wrapper .gfield_error input:focus, .gform_wrapper .gfield_error select:focus, .gform_wrapper .gfield_error textarea:focus {
  border-color: #b94a48;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(185, 74, 72, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(185, 74, 72, 0.6); }

.validation_error {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48; }

.validation_error h4 {
  margin-top: 0;
  color: inherit; }

.validation_error .alert-link {
  font-weight: bold; }

.validation_error>p, .validation_error>ul {
  margin-bottom: 0; }

.validation_error>p+p {
  margin-top: 5px; }

.validation_error hr {
  border-top-color: #e6c1c7; }

.validation_error .alert-link {
  color: #953b39; }

#gforms_confirmation_message {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px; }

#gforms_confirmation_message h4 {
  margin-top: 0;
  color: inherit; }

#gforms_confirmation_message .alert-link {
  font-weight: bold; }

#gforms_confirmation_message>p, #gforms_confirmation_message>ul {
  margin-bottom: 0; }

#gforms_confirmation_message>p+p {
  margin-top: 5px; }

.gallery-row {
  padding: 15px 0; }

.aligncenter {
  display: block;
  margin: 0 auto; }

.alignleft {
  float: left; }

.alignright {
  float: right; }

figure.alignnone {
  margin-left: 0;
  margin-right: 0; }
.ui-widget {
  background: white;
  padding: 10px;
  border-radius: 0px;
  font-size:16px;
  border: 1px solid #ccc;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.125);
}
.gsection_description {
	font-size:16px;
}
.gform_drop_instructions {
	font-weight:300;
}

.ui-widget td {
  text-align: center;
  padding: 3px 0;
  /*border: 1px solid #ccc;*/
}
.ui-widget td a {
  padding: 3px 8px;
}

.ui-datepicker-next {
  float: right;
}

.gform_fields {
	margin: 0 -15px;
}
.gform_fields .col-md-12 {
	padding: 0 15px;
}
.gform_wrapper.col-md-9_wrapper {
	margin: 0 -15px;
}


.gform_wrapper span label {
  font-weight: normal;	
  font-style:normal;
  font-size:14px;
  line-height:14px;
  margin-bottom:8px;
}

.gform_wrapper .gfield_label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
  font-style: normal;
  font-size:18px;
}


.fake-browser-ui {
    padding: 20px 0 0;
    border-radius: 3px;
    border-bottom: 2px solid #ccc;
    background: #cdcdcd;
    display: inline-block;
    position: relative;
    margin: 40px 30px;
    line-height: 0;
    box-shadow: 0px 0px 16px rgba(0,0,0,.18);
}
.full_width .fake-browser-ui {
    margin: 40px 0px;

}
.fake-browser-ui .frame {
    display: block;
    height: 15px;
    position: absolute;
    top: 5px;
    left: 1px;
}
.fake-browser-ui span {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: #eee;
    border: 1px solid #dadada;
    float: left;
    margin: 0 0 0 4px;
}



.fake-phone-ui {
    padding: 60px 10px;
    border-radius: 24px;
    background: #222;
    display: inline-block;
    position: relative;
    margin: 40px 30px;
    line-height: 0;
    box-shadow: 0px 0px 16px rgba(0,0,0,.18);
}
.fake-phone-ui img {
		width:250px;
		height:auto;
}

.full_width .fake-phone-ui {
    margin: 40px 0px;
}



.fake-paper-ui {
    display: inline-block;
    position: relative;
    margin: 40px 30px;
    line-height: 0;
    box-shadow: 0px 0px 16px rgba(0,0,0,.12);
}

.fake-tablet-ui {
    padding: 20px;
    border-radius: 24px;
    background: #222;
    display: inline-block;
    position: relative;
    margin: 40px 30px;
    line-height: 0;
    box-shadow: 0px 0px 16px rgba(0,0,0,.18);
}



.bgAccentColor {
	padding:10px;
	margin:0px 0px 20px;
}
main img {
height:auto;
}
#project_rows .row {
   display: flex;
    align-items: center;
    margin:15px 0px;
}
#project_rows .row img {
	max-width: 100%;
}

#project_rows .full_width {
	padding-left:100vw;
	margin-left:-100vw;
}
main ul {
	font-family: 'Glober', sans-serif;
	padding-left:20px;
	line-height: 28px;
	font-size:17px;
}


#services_tabs {
	padding-top:80px;
	font-size: 16px;
}
#services_tabs li {
	margin-bottom: 15px;
}
#services_tabs li:nth-child(3n) {
	clear:left;
}
#services_tabs li a {
	padding: 3px 10px;
}

.block a {
	background:none;
}
#ui-datepicker-div {
	display:none;
}
.modal-backdrop.in {
	opacity:.8;
}
.modal {
	z-index:1041;
	text-align:center;
}
.modal-dialog {
	width:auto;
	max-width:100%;
	display:inline-block;
}
.modal-dialog img {
	max-width:100%;
}
.modal-content {
	background-color:rgba(0,0,0,0);
	box-shadow:none;
}
.modal-header {
	padding-top:0px;
}
.modal-title {
	color:#eee;
	text-align:center;
}
.modal-body {
	margin: 0 auto;
	text-align:center;
	display:inline-block;
}
.modal-header .close {
	color:#fff;
}


@media (max-width: @screen-sm-min) {
body.blog article.block {
	margin-bottom:30px;
	padding-bottom:20px;
	border-bottom: 1px solid #eee;
}

.gform_wrapper.col-md-9_wrapper {
    margin: 0px 0px;
}
.gform_fields .col-md-12 {
    padding: 0 10px;
}
.fake-paper-ui {
    display: inline-block;
    position: relative;
    margin: 0px;
    line-height: 0;
    box-shadow: 0px 0px 6px rgba(0,0,0,.12);
}
}
@media (max-width: @screen-xs) {
	.fake-browser-ui {
    padding: 0px 0 0;
    border-radius: 0px;
    border: 1px solid #ccc;
    background: none;
    display: block;
    position: static;
    margin: 30px 0px 10px;
    line-height: 0;
    box-shadow: 0px 0px 6px rgba(0,0,0,.18);
}
	.fake-browser-ui:before {
	font-size: 12px;
position:relative;
top:-14px;
	content:'Browser View';
}
.full_width .fake-browser-ui {
    margin: 30px 0px 10px;

}
.fake-browser-ui .frame {
    display: none;
}
.fake-browser-ui span {
    display: none;
}



.fake-phone-ui {
    padding: 0px 0 0;
    border-radius: 0px;
    border: 0px;
    background: none;
    display: block;
    position: static;
    margin: 30px 0px 10px;
    line-height: 0;
    box-shadow: none;
}
	.fake-phone-ui:before {
	font-size: 12px;
	content:'Phone View';

position:relative;
top:-10px;
    float:left;
}
 
 .fake-phone-ui img {
		width:250px;
		height:auto;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 6px rgba(0,0,0,.18);
	clear:both;
}

.full_width .fake-phone-ui {
    margin: 30px 0px 10px;
}




.fake-tablet-ui {
    padding: 0px 0 0;
    border-radius: 0px;
    border: 0px;
    background: none;
    display: block;
    position: static;
    margin: 30px 0px 10px;
    line-height: 0;
    box-shadow: none;
}

 .fake-tablet-ui img {
    border: 1px solid #ccc;
    box-shadow: 0px 0px 6px rgba(0,0,0,.18);
	clear:both;
}
	.fake-tablet-ui:before {
	font-size: 12px;
	content:'Tablet View';

position:relative;
top:-10px;
    float:left;
}


}